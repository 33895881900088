import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import logo from '../img/logo.png';

const Header = () => {
    const location = useLocation()

    React.useEffect(() => {
        window.onscroll = () => { topnavScroll() };

        const mobileItems = document.querySelectorAll('.js--mobile-item');
        const mobileToggle = document.querySelector('#js--mobile-toggle');

        mobileItems.forEach(element => {
            element.onclick = () => {
                mobileToggle.checked = false;
            }
        });

        const navHeader = document.querySelector('#js--header');
        const navLogo = document.querySelector('#js--logo');
        const mobileNav = document.querySelector('#js--hamburger');

        const topnavScroll = () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                navHeader.style.backgroundColor = '#0F1729';
                navHeader.style.padding = "10px 0";
                navLogo.style.maxWidth = "120px";
                mobileNav.style.top = "7px";
            } else {
                navHeader.style.backgroundColor = 'rgba(0,0,0,0)';
                navHeader.style.padding = "20px 0";
                navLogo.style.maxWidth = "150px";
                mobileNav.style.top = "25px";
            }
        }
    }, []);

    return (
        <header className="header" id="js--header">
            <div className="header__flex container">
                <Link to="/">
                    <figure className="logo" id="js--logo">
                        <img className="logo__img" src={logo} alt="Logo van Draze.nl" />
                    </figure>
                </Link>
                <nav className="nav">
                    <ul className="nav__list">
                        <li><Link to="/" className={"nav__item " + (location.pathname === '/' && ( 'nav__item--active '))}>Home</Link></li>
                        <li><Link to="/websites" className={"nav__item " + (location.pathname === '/websites' && ( 'nav__item--active '))}>Websites</Link></li>
                        <li><Link to="/applicaties" className={"nav__item " + (location.pathname === '/applicaties' && ( 'nav__item--active '))}>Applicaties</Link></li>
                        <li><Link to="/projecten" className={"nav__item " + (location.pathname === '/projecten' && ( 'nav__item--active '))}>Projecten</Link></li>
                        <li><Link to="/contact" className={"nav__item nav__item--btn " + (location.pathname === '/contact' && ( 'nav__item--active '))}>Contact</Link></li>
                    </ul>
                </nav>

                <nav className="mobile-nav" id="js--hamburger">
                    <input id="js--mobile-toggle" className="checkbox-toggle" type="checkbox" />
                    <div className="hamburger">
                        <div></div>
                    </div>
                    <div className="menu">
                        <div id="js--mobile-menu">
                            <div>
                                <ul>
                                    <li className="js--mobile-item"><Link to="/">Home</Link></li>
                                    <li className="js--mobile-item"><Link to="/websites">Websites</Link></li>
                                    <li className="js--mobile-item"><Link to="/applicaties">Applicaties</Link></li>
                                    <li className="js--mobile-item"><Link to="/projecten">Projecten</Link></li>
                                    <li className="js--mobile-item"><Link to="/contact">Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>

            </div>
        </header>
    )
}

export default Header
