import { Link } from 'react-router-dom'

const Alert = () => {
    return (
        <section className="alert">
            <div className="container-small">
                <h2 className="subtitle subtitle--medium" data-aos="fade" data-aos-delay="100">Contact</h2>
                <h1 className="title title--white" data-aos="fade" data-aos-delay="100">Neem vrijblijvend contact met Draze op</h1>
                <p className="desc desc--white" data-aos="fade" data-aos-delay="200">Twijfel niet om contact met Draze op te nemen indien u de mogelijkheden wilt bespreken voor uw (toekomstige) website of applicatie. Ook voor vragen over een onze diensten, bestaande toepassingen of andere zaken kunt u contact met ons opnemenen.</p>
                <p className="desc desc--white" data-aos="fade" data-aos-delay="200">Vind ons op <a href="https://www.facebook.com/drazewebdesign" className="link" rel="noreferrer" target="_blank">Facebook</a> of volg ons op <a href="https://www.instagram.com/draze.nl/" className="link" rel="noreferrer" target="_blank">Instagram</a>.</p>
                <div data-aos="fade-up" data-aos-delay="300">
                    <Link to="/contact" className="btn btn--margin">Contact</Link>
                </div>
            </div>
        </section>
    )
}

export default Alert
