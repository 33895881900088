const WebsiteColumns = () => {
    return (
        <section className="container">
            <section className="column-grid">
                <article data-aos="fade" data-aos-delay="100">
                    <h2 className="articletitle">Een website laten maken</h2>
                    <p className="desc">Draze verzorgt het ontwerp en de volledige ontwikkeling van uw maatwerk website. Wij werken uitsluitend met zelfgeschreven code. Dit betekent dat wij geen WYSIWYG (What you see is what you get) software zoals WordPress gebruiken. Dit betekent dat websites die door ons gemaakt zijn vaak sneller, veiliger en uniek zijn.</p>
                    <p className="desc">Op basis van de wensen van de klant en wat de functionaliteit moet zijn van de website maken wij een keuze hoe de website ontwikkeld gaat worden. Voor complexere websites of webapplicaties is het mogelijk dat wij gebruik maken van frameworks zoals React of Laravel. Hiermee kunnen wij al uw wensen vervullen. Ook is het mogelijk om een bestaande CMS te installeren zoals Statamic, een maatwerk CMS te realiseren voor uw organisatie of door de Draze CMS te gebruiken. Hiermee kunt u zelf de content op uw website beheren.</p>
                    <ul className="method__list">
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Oneindige mogelijkheden</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Verhoog uw conversie</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Vergroot uw online zichtbaarheid</li>
                    </ul>
                </article>
                <article data-aos="fade" data-aos-delay="200">
                    <h2 className="articletitle">Onze aanpak</h2>
                    <p className="desc">In de eerste fase luisteren wij goed naar de wensen van u. Dit gebeurt vaak via een online vergadering. Hierbij bespreken wij uw wensen en wat de mogelijkheden hiervoor zijn. Tijdens deze brainstorm sturen wij u nog een debriefing op met onze ideeën zodat we zeker weten dat uw wensen goed zijn overgekomen.</p>
                    <p className="desc">Het is mogelijk dat u al een ontwerp van een website heeft, of zelfs een bestaande website. Wij kunnen deze dan gebruiken om de website te realiseren of te optimaliseren. Vaak hebben onze klanten slechts een idee van hun toekomstige website. Hierom verzorgen wij ook het volledige ontwerp.</p>
                    <p className="desc">Al uw wensen worden vertaald in een digitale schets zodat u het eindresultaat alvast kunt zien alvorens de website daadwerkelijk geprogrammeerd is. Nadat u volledig tevreden bent zullen wij een planning maken voor de ontwikkeling van de website en zal de website snel worden opgeleverd.</p>
                    <p className="desc">U kunt via een live demo omgeving op ieder gewenst moment meekijken tijdens de progressie van de ontwikkeling van uw website. Ruim voor de afgesproken deadline is uw website klaar om opgeleverd te worden.</p>
                </article>
            </section>
        </section>
    )
}

export default WebsiteColumns
