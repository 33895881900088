import { Link } from 'react-router-dom'
import avions from '../img/avions.jpg';
import demeerkoet from '../img/demeerkoet.jpg';
import serkiee from '../img/serkiee.jpg';

const RecentWebsites = () => {
    return (
        <section className="recent-opgeleverd">
            <div className="container">
                <header className="recent-opgeleverd__header" data-aos="fade" data-aos-delay="100">
                    <h2 className="subtitle subtitle--medium">Showcase</h2>
                    <h1 className="title title--center title--white">Website voorbeelden</h1>
                </header>
                <section className="case-grid">
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="case-grid__figure">
                            <img src={serkiee} alt="Afbeelding van Serkiee Website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Serkiee Website</h2>
                        <p className="desc desc--white">Ontwikkeling van one pager met bijbehorend op maat gemaakt content management systeem voor eigen contentbeheer.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199349-Mobile-friendly-website-for-Serkiee" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="200">
                        <figure className="case-grid__figure">
                            <img src={avions} alt="Afbeelding van Avions.us Website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Avions.us Website</h2>
                        <p className="desc desc--white">Mobielvriendelijke website voor een Amerikaans toonaangevend consultancybureau in productie technologie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199364-One-page-responsive-website" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="300">
                        <figure className="case-grid__figure">
                            <img src={demeerkoet} alt="Afbeelding van Beachclub de Meerkoet Website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Beachclub de Meerkoet Website</h2>
                        <p className="desc desc--white">Website inclusief op maat gemaakt content management systeem waarmee content eenvoudig en razendsnel bewerkt kan worden.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199377-Responsive-website-for-a-beachclub" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                </section>
                <footer className="recent-opgeleverd__footer" data-aos="fade-up" data-aos-delay="100">
                    <Link to="/projecten" className="btn">Bekijk meer projecten</Link>
                </footer>
            </div>
        </section>
    )
}

export default RecentWebsites
