import { Link } from 'react-router-dom'
import tesla from '../img/tesla.jpg';
import gripio from '../img/gripio.jpg';
import qreca from '../img/qreca.jpg';

const RecentlyDelivered = () => {
    return (
        <section className="recent-opgeleverd">
            <div className="container">
                <header className="recent-opgeleverd__header" data-aos="fade" data-aos-delay="100">
                    <h2 className="subtitle subtitle--medium">Showcase</h2>
                    <h1 className="title title--center title--white">Recent opgeleverd</h1>
                </header>
                <section className="case-grid">
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="case-grid__figure">
                            <img src={qreca} alt="Afbeelding van Qreca website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Qreca App</h2>
                        <p className="desc desc--white">Full stack development van de SaaS applicatie Qreca. Digitalisering van de menu- en allergenenkaart voor de horeca.</p>
                        <p className="desc"><a href="https://qreca.nl/" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="200">
                        <figure className="case-grid__figure">
                            <img src={tesla} alt="Afbeelding van Tesla Superchargers App" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Tesla Superchargers App</h2>
                        <p className="desc desc--white">Geografische applicatie voor visuele weergave van Tesla Superchargers. Inclusief filters en routing op basis van gebruikerslocatie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199323-Tesla-Superchargers-GEO-Webapp" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="300">
                        <figure className="case-grid__figure">
                            <img src={gripio} alt="Afbeelding van Gripio App" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle smalltitle--white">Gripio App</h2>
                        <p className="desc desc--white">Gripio is een platform voor lokale winkeliers. Draze is verantwoordelijk voor de fullstack ontwikkeling van deze maatwerk webapplicatie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199341-Gripio-App" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                </section>
                <footer className="recent-opgeleverd__footer" data-aos="fade-up" data-aos-delay="100">
                    <Link to="/projecten" className="btn">Bekijk meer projecten</Link>
                </footer>
            </div>
        </section>
    )
}

export default RecentlyDelivered
