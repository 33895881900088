import icon_nativeapp from '../img/icon_nativeapp.png';
import icon_hybrideapp from '../img/icon_hybrideapp.png';
import icon_webapp from '../img/icon_webapp.png';

const AppTypes = () => {
    return (
        <section className="container">
            <header className="app-types">
                <h2 className="subtitle subtitle--light" data-aos="fade" data-aos-delay="100">Soorten apps</h2>
                <h1 className="title title--center" data-aos="fade" data-aos-delay="100">Verschillende vormen van apps</h1>
            </header>
            <section className="column-grid">
                <article data-aos="fade" data-aos-delay="100">
                    <h2 className="articletitle">Verschillende technieken</h2>
                    <p className="desc">Bij de ontwikkeling van een app zijn er verschillende technieken mogelijk. Dit zorgt er automatisch voor dat er ook verschillende soorten applicaties bestaan. Wij realiseren intuïtieve oplossingen zodat u direct aan de slag kunt gaan met uw nieuwe maatwerk applicatie.</p>
                    <ul className="method__list">
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Gebruiksvriendelijk</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Offline gebruik</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Veilig, snel en intuïtief</li>
                    </ul>
                    <p className="desc">Een native app wordt ontwikkeld voor één specifiek platform met de bijbehorende programmeertaal. Een native app voor zowel Android als iOS zal twee keer in verschillende programmeertalen ontwikkeld moeten worden.</p>
                    <p className="desc">Voor de ontwikkeling van hybride apps maken wij gebruik van React Native. Deze techniek levert vergelijkbare prestaties als een native app. Deze oplossing maakt gebruik van de systeemfuncties van zowel Android als iOS zodat de app aanvoelt zoals je gewend bent.</p>
                    <p className="desc">Voor de ontwikkeling van een maatwerk webapplicatie maken wij vaak gebruik van Laravel en React. Deze frameworks kunnen veel en daarmee zorgen wij voor een passende oplossing voor uw probleem. Wij zijn erg ervaren in de ontwikkeling van webapplicaties en raden deze type app ook aan.</p>
                </article>
                <section className="app-types__flex">
                    <article className="app-types__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="app-types__figure">
                            <img className="app-types__img" src={icon_nativeapp} alt="Icon van app type Native" />
                        </figure>
                        <h2 className="smalltitle smalltitle--center">Native app</h2>
                        <p className="desc">Native applicaties zijn afhankelijk van een bepaald platform en te downloaden in de App Store of de Play Store.</p>
                    </article>
                    <article className="app-types__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="app-types__figure">
                            <img className="app-types__img" src={icon_hybrideapp} alt="Icon van app type Hybride" />
                        </figure>
                        <h2 className="smalltitle smalltitle--center">Hybride app</h2>
                        <p className="desc">Een hybride app werkt direct op zowel Android als iOS apparaten.</p>
                    </article>
                    <article className="app-types__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="app-types__figure">
                            <img className="app-types__img" src={icon_webapp} alt="Icon van app type Web" />
                        </figure>
                        <h2 className="smalltitle smalltitle--center">Web app</h2>
                        <p className="desc">Een webapplicatie is vaak ideaal voor het digitaliseren en automatiseren van (bedrijfs)processen.</p>
                    </article>
                </section>
            </section>
        </section>
    )
}

export default AppTypes
