import tesla from '../img/tesla.jpg';
import gripio from '../img/gripio.jpg';
import serkiee from '../img/serkiee.jpg';
import kentekenchecker from '../img/kentekenchecker.jpg';
import avions from '../img/avions.jpg';
import demeerkoet from '../img/demeerkoet.jpg';
import qreca from '../img/qreca.jpg';

const Cases = () => {
    return (
        <section className="cases">
            <div className="container">
                <header className="cases__header">
                    <h2 className="subtitle subtitle--light" data-aos="fade" data-aos-delay="100">Cases</h2>
                    <h1 className="title title--center" data-aos="fade" data-aos-delay="100">Ons werk</h1>
                    <p className="desc" data-aos="fade" data-aos-delay="200">Een aantal van onze projecten staan uitgelicht op deze pagina. Daarnaast plaatsen wij ook regelmatig op social media afbeeldingen van gemaakte projecten. Slechts een klein aantal van onze projecten worden gepubliceerd op deze pagina en op onze socials. U kunt deze projecten bekijken ter referentie van ons gemaakte werk.</p>
                </header>
                <section className="case-grid">
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="case-grid__figure">
                            <img src={tesla} alt="Afbeelding van Tesla Superchargers App" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Tesla Superchargers App</h2>
                        <p className="desc">Geografische applicatie voor visuele weergave van Tesla Superchargers. Inclusief filters en routing op basis van gebruikerslocatie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199323-Tesla-Superchargers-GEO-Webapp" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="200">
                        <figure className="case-grid__figure">
                            <img src={gripio} alt="Afbeelding van Gripio App" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Gripio App</h2>
                        <p className="desc">Gripio is een platform voor lokale winkeliers. Draze is verantwoordelijk voor de fullstack ontwikkeling van deze maatwerk webapplicatie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199341-Gripio-App" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="300">
                        <figure className="case-grid__figure">
                            <img src={serkiee} alt="Afbeelding van Serkiee website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Serkiee Website</h2>
                        <p className="desc">Ontwikkeling van one pager met bijbehorend op maat gemaakt content management systeem voor eigen contentbeheer.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199349-Mobile-friendly-website-for-Serkiee" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="case-grid__figure">
                            <img src={kentekenchecker} alt="Afbeelding van Kenteken Checker App" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Kenteken Checker App</h2>
                        <p className="desc">De Kenteken Checker App geeft een overzicht van de meest gezochte gegevens over voertuigen zoals de APK vervaldatum.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199359-License-plate-checker-App" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="200">
                        <figure className="case-grid__figure">
                            <img src={avions} alt="Afbeelding van Avions.us website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Avions.us Website</h2>
                        <p className="desc">Mobielvriendelijke website voor een Amerikaans toonaangevend consultancybureau in productie technologie.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199364-One-page-responsive-website" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="300">
                        <figure className="case-grid__figure">
                            <img src={demeerkoet} alt="Afbeelding van Beachclub de Meerkoet website" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Beachclub de Meerkoet Website</h2>
                        <p className="desc">Website inclusief op maat gemaakt content management systeem waarmee content eenvoudig en razendsnel bewerkt kan worden.</p>
                        <p className="desc"><a href="https://dribbble.com/shots/15199377-Responsive-website-for-a-beachclub" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                    <article className="case-grid__item" data-aos="fade-up" data-aos-delay="100">
                        <figure className="case-grid__figure">
                            <img src={qreca} alt="Afbeelding van Qreca app" className="case-grid__img"/>
                        </figure>
                        <h2 className="smalltitle">Qreca App</h2>
                        <p className="desc">Full stack development van de SaaS applicatie Qreca. Digitalisering van de menu- en allergenenkaart voor de horeca.</p>
                        <p className="desc"><a href="https://qreca.nl/" className="link" rel="noreferrer" target="_blank">Bekijk project</a></p>
                    </article>
                </section>
                <footer className="cases__footer" data-aos="fade-up" data-aos-delay="100">
                    <a className="btn" href="https://www.instagram.com/draze.nl/" rel="noreferrer" target="_blank">Volg ons op Instagram</a>
                </footer>
            </div>
        </section>
    )
}

export default Cases
