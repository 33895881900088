import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Link } from 'react-router-dom'
import CookieConsent from "react-cookie-consent";
import Header from './components/Header'
import Banner from './components/Banner'
import SubBanner from './components/SubBanner'
import MethodGrid from './components/MethodGrid'
import Method from './components/Method'
import RecentlyDelivered from './components/RecentlyDelivered'
import Info from './components/Info'
import Cases from './components/Cases'
import Terms from './components/Terms'
import Privacy from './components/Privacy'
import Alert from './components/Alert'
import Preface from './components/Preface'
import Collapsible from './components/Collapsible'
import RecentWebsites from './components/RecentWebsites'
import RecentApplications from './components/RecentApplications'
import WebsiteColumns from './components/WebsiteColumns'
import ApplicationColumns from './components/ApplicationColumns'
import AppTypes from './components/AppTypes'
import Contact from './components/Contact'
import Footer from './components/Footer'
import './App.scss'

const App = () => {
  React.useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
    });
  }, []);

  return (
    <Router>
      <>
        <Header /> 

        <Route path='/' exact render={() => (
          <>
            <Banner />
            <MethodGrid />
            <Method />
            <RecentlyDelivered />
            <Info />
            
          </>
        )} />
          
        <Route path='/websites' exact render={() => (
          <>
            <SubBanner heading="Website realisatie op maat" name="Websites" />
            <Preface 
              subtitle="Draze.nl" 
              title="Maatwerk websites" 
              desc="Een maatwerk website voldoet aan al uw wensen. Draze realiseert deze maatwerk oplossing graag voor u. Van informatieve websites tot aan complexe websites met complexe functionaliteiten. Naast de ontwikkeling van de website is Draze ook actief op het gebied van ontwerp en zoekmachine optimalisatie."
            />
            <WebsiteColumns />
            <RecentWebsites />
            <Collapsible />

          </>
        )} />

        <Route path='/applicaties' exact render={() => (
          <>
            <SubBanner heading="Applicatie ontwikkeling" name="Applicaties" />
            <Preface 
              subtitle="Draze.nl" 
              title="Een eigen applicatie" 
              desc="Wilt u (bedrijfs)processen laten automatiseren of digitaliseren? Een webapplicatie is geschikt voor diverse toepassingen met eindeloze mogelijkheden. Draze helpt u graag verder bij de ontwikkeling van maatwerk applicaties. Ook is het mogelijk om samen naar een businessmodel (Software as a Service) te kijken."
            />
            <ApplicationColumns />
            <RecentApplications />
            <AppTypes />
          </>
        )} />

        <Route path='/projecten' exact render={() => (
          <>
            <SubBanner heading="Een aantal van onze projecten" name="Projecten" />
            <Cases />
          </>
        )} />

        <Route path='/contact' exact render={() => (
          <>
            <SubBanner heading="Contact" name="Contact" />
            <Preface 
              subtitle="Contact" 
              title="Draze staat voor u klaar" 
              desc="Mochten wij naar aanleiding van de informatie op onze website u hebben overtuigd, neem dan contact met ons op om de mogelijkheden voor u te bespreken. Wanneer uw gewenste dienst niet staat uitgelicht op onze website is het toch goed mogelijk dat Draze een maatwerk oplossing kan vinden. Wij streven ernaar om binnen 5 werkdagen te reageren op uw verzoek."
            />
            <Contact />
          </>
        )} />

        <Route path='/algemene-voorwaarden' exact render={() => (
          <>
            <SubBanner heading="Algemene voorwaarden" name="Algemene voorwaarden" />
            <Terms />
          </>
        )} />

        <Route path='/privacyverklaring' exact render={() => (
          <>
            <SubBanner heading="Privacyverklaring" name="Privacyverklaring" />
            <Privacy />
          </>
        )} />

        <Alert />
        <Footer />
        <CookieConsent
          buttonText="Ik ga akkoord"
          disableButtonStyles="true"
          buttonClasses="cookiebtn"
        >Draze.nl maakt gebruik van cookies om de gebruikerservaring te verbeteren. <Link to="/privacyverklaring" className="link" >Meer informatie</Link>.</CookieConsent>
      </>
    </Router>
  );
}

export default App;