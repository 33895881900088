import laptops from '../img/laptops.png';

const Info = () => {
    return (
        <section className="info">
            <div className="container">
                <header className="info__header" data-aos="fade" data-aos-delay="100">
                    <h2 className="subtitle subtitle--light">Voordelen</h2>
                    <h1 className="title title--center">Draze levert maatwerk</h1>
                    <figure className="info__figure">
                        <img src={laptops} alt="Drie laptops met illustraties van Tesla Superchargers app, de Meerkoet website en Kenteken Checker App" className="info__img"/>
                    </figure>
                </header>
                <main className="info__flex">
                    <article className="info__content" data-aos="fade" data-aos-delay="100">
                        <p className="desc">Een website is niet alleen uw online visitekaartje, een website is veel meer dan dat. De website draagt bij aan de vindbaarheid van uw bedrijf en is bedoeld om een potentiële klant verder te helpen. Een goede website zorgt uiteindelijk voor meer omzet. De website wordt dusdanig ontwikkeld zodat de website beschikbaar is voor alle devices. Wist u dat alle websites tegenwoordig voornamelijk worden bezocht via de telefoon? Draze luistert naar de klant. Zo zit u bijvoorbeeld niet aan een maximaal aantal aanpassingen vast, u kunt zo veel aanpassen als u wilt om 100% tevreden te zijn met uw website.</p>
                    </article>
                    <ul className="info__list" data-aos="fade-left" data-aos-delay="100">
                        <li className="info__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Mobielvriendelijk</li>
                        <li className="info__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> 100% tevredenheidsgarantie</li>
                        <li className="info__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Veilige applicatie</li>
                        <li className="info__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Snel en geoptimaliseerd</li>
                        <li className="info__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Nieuwste standaarden</li>
                    </ul>
                </main>
            </div>
        </section>
    )
}

export default Info
