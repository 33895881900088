import PropTypes from 'prop-types'

const Preface = ({ subtitle, title, desc }) => {
    return (
        <section className="preface">
            <div className="container-small">
                <header className="preface__header">
                    <div data-aos="fade" data-aos-delay="100">
                        <h2 className="subtitle subtitle--light">{subtitle}</h2>
                        <h1 className="title title--center">{title}</h1>
                    </div>
                    <div data-aos="fade" data-aos-delay="200">
                        <p className="desc">{desc}</p>
                    </div>
                </header>
            </div>
        </section>
    )
}

Preface.defaultProps = {
    subtitle: 'Draze.nl',
    title: 'Ontwerp en realisatie van maatwerk applicaties',
    desc: 'Neem vrijblijvend contact op met Draze. Heeft u een vraag of wilt u wat informatie verkrijgen? Geen probleem! Draze staat voor u klaar. Ook wanneer u de mogelijkheden voor uw (toekomstige) website wilt bespreken kunt u contact opnemen met Draze.',
}

Preface.propTypes = {
    subtitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
}

export default Preface