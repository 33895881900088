import { Link } from 'react-router-dom'
import React from 'react'

const Collapsible = () => {
    React.useEffect(() => {
        const items = document.querySelectorAll(".accordion button");

        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded');
            
            for (let i = 0; i < items.length; i++) {
                items[i].setAttribute('aria-expanded', 'false');
            }
            
            if (itemToggle == 'false') {
                this.setAttribute('aria-expanded', 'true');
            }
        }
        
        items.forEach(item => item.addEventListener('click', toggleAccordion));
    }, []);

    return (
        <section className="container">
            <header className="collapsible__header">
                <h2 className="subtitle subtitle--light" data-aos="fade" data-aos-delay="100">FAQ</h2>
                <h1 className="title" data-aos="fade" data-aos-delay="100">Veelgestelde vragen</h1>
            </header>
            <article className="collapsible">
                <div className="collapsible__image" data-aos="fade" data-aos-delay="200"></div>
                <div className="accordion" data-aos="fade-left" data-aos-delay="200">
                    <article className="accordion-item">
                        <button id="accordion-button-1" aria-expanded="true">
                            <span className="icon" aria-hidden="true"></span>
                            <span className="accordion-title">Hoe duur is een website?</span>
                        </button>
                        <div className="accordion-content">
                            <p>Elk project is verschillend. Er is geen vaste prijs te koppelen aan de ontwikkeling van een website. Voordat wij het project starten spreken bij een prijs af zodat u niet voor verassingen komt te staan.</p>
                        </div>
                    </article>

                    <article className="accordion-item">
                        <button id="accordion-button-2" aria-expanded="false">
                            <span className="icon" aria-hidden="true"></span>
                            <span className="accordion-title">Is de website vindbaar?</span>
                        </button>
                        <div className="accordion-content">
                            <p>Doordat wij de website optimaliseren, de nieuwste standaarden gebruiken en semantische code gebruiken is uw website goed vindbaar in de zoekmachines.</p>
                        </div>
                    </article>

                    <article className="accordion-item">
                        <button id="accordion-button-3" aria-expanded="false">
                            <span className="icon" aria-hidden="true"></span>
                            <span className="accordion-title">Maken jullie ook PWA's?</span>
                        </button>
                        <div className="accordion-content">
                            <p>Wij maken ook progressive web apps. Voor meer informatie over deze progressive web apps en de kracht hiervan kun u meer lezen op de pagina <Link to="/applicaties" className="link">applicaties</Link>.</p>
                        </div>
                    </article>

                    <article className="accordion-item">
                        <button id="accordion-button-4" aria-expanded="false">
                            <span className="icon" aria-hidden="true"></span>
                            <span className="accordion-title">Realiseren jullie webshops?</span>
                        </button>
                        <div className="accordion-content">
                            <p>Ook kan Draze ondersteunen bij de ontwikkeling van webshops en e-commerce toepassingen. Voor meer informatie over de mogelijkheden kunt u <Link to="/contact" className="link">contact</Link> met ons opnemen.</p>
                        </div>
                    </article>

                    <article className="accordion-item">
                        <button id="accordion-button-5" aria-expanded="false">
                            <span className="icon" aria-hidden="true"></span>
                            <span className="accordion-title">Is de website snel?</span>
                        </button>
                        <div className="accordion-content">
                            <p>Door te werken met best practices en optimalisatie streven wij naar een zo hoog mogelijke score op het gebied van kwaliteit, snelheid en correctheid.</p>
                        </div>
                    </article>
                </div>
            </article>
        </section>
    )
}

export default Collapsible
