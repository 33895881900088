const ApplicationColumns = () => {
    return (
        <section className="container">
            <section className="column-grid">
                <article data-aos="fade" data-aos-delay="100">
                    <h2 className="articletitle">Maatwerk applicatie</h2>
                    <p className="desc">Het is goed mogelijk om diverse soorten processen te automatiseren of te digitaliseren. Vaak is een webapplicatie ideaal voor het digitaliseren van een bedrijfsproces. Deze webapplicaties kunnen erg uiteenlopend zijn en daarom bespreken wij graag wat u in gedachten heeft.</p>
                    <p className="desc">Bent u opzoek naar de ontwikkeling van een mobiele app voor in de App Store of Play Store? Ook dan is Draze voor u geschikt. Wij hebben ervaring met de ontwikkeling van fullstack android apps en kunnen u ondersteunen bij de uitwerking hiervan. Lees meer over de verschillende type apps onderaan deze pagina.</p>
                </article>
                <article data-aos="fade" data-aos-delay="200">
                    <h2 className="articletitle">Software as a Service (SaaS)</h2>
                    <p className="desc">Draze is erg actief op het gebied van SaaS. Wij denken graag met u mee voor uw eigen SaaS. Heeft u een goed idee waarvan u denkt dat het mogelijk is om dit om te zetten in een businessmodel? Neem gerust contact met ons op om te kijken wat de mogelijkheden zijn. Indien wij evenveel potentie zien in uw idee zijn wij ook bereid voor het principe Developing SaaS for Equity.</p>
                </article>
            </section>
        </section>
    )
}

export default ApplicationColumns
