import icon_contact from '../img/icon_contact.png';
import icon_socialmedia from '../img/icon_socialmedia.png';

const Contact = () => {
    return (
        <section className="container">
            <section className="grid grid--double">
                <article className="grid__item" data-aos="fade-up" data-aos-delay="100">
                    <figure className="grid__figure grid__figure--left">
                        <img className="grid__img" src={icon_contact} alt="Icon over e-mail en telefonisch contact" />
                    </figure>
                    <div className="grid__spacer">
                        <h2 className="smalltitle">Stel uw vraag via e-mail of telefonisch</h2>
                        <p className="desc">Doorgaans binnen 5 werkdagen antwoord. Ook kunt u mailen om een belafspraak te maken.</p>
                    </div>
                    <a href="mailto:info@draze.nl" className="btn" rel="noreferrer" target="_blank">Verstuur een e-mail</a>
                </article>
                <article className="grid__item" data-aos="fade-up" data-aos-delay="200">
                    <figure className="grid__figure grid__figure--left">
                        <img className="grid__img" src={icon_socialmedia} alt="Icon over social media contact" />
                    </figure>
                    <div className="grid__spacer">
                        <h2 className="smalltitle">Stel uw vraag via social media</h2>
                        <p className="desc">Stel uw vraag via <a href="https://www.facebook.com/drazewebdesign" className="link" rel="noreferrer" target="_blank">Facebook</a> of <a href="https://www.instagram.com/draze.nl/" className="link" rel="noreferrer" target="_blank">Instagram</a> en ontvang doorgaans binnen 5 werkdagen antwoord.</p>
                    </div>
                    <a href="https://www.facebook.com/drazewebdesign" className="btn" rel="noreferrer" target="_blank">Facebook messenger</a>
                </article>
            </section>
        </section>
    )
}

export default Contact
