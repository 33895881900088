import React from 'react'

const Banner = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <section className="banner">
            <div className="banner__flex container-small">
                <h2 className="subtitle subtitle--medium subtitle--center" data-aos="fade" data-aos-delay="100">Draze.nl</h2>
                <div>
                    <h1 className="title title--center title--white">Ontwerp en realisatie van maatwerk applicaties</h1>
                </div>
            </div>
        </section>
    )
}

export default Banner
