import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const SubBanner = ({ heading, name }) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <section className="banner banner--small">
            <div className="banner__flex banner__flex--left container">
                <h1 className="title title--white" data-aos="fade-right" data-aos-delay="100">{heading}</h1>
                <ul className="breadcrumb" data-aos="fade-right" data-aos-delay="200">
                    <li className="breadcrumb__item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb__item">{name}</li>
                </ul>
            </div>
        </section>
    )
}

SubBanner.defaultProps = {
    heading: 'Draze.nl',
    name: 'Home',
}

SubBanner.propTypes = {
    heading: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default SubBanner