import { Link } from 'react-router-dom'
import kentekenchecker from '../img/kentekenchecker.png';

const Method = () => {
    return (
        <section className="container">

            <section className="method">
                <article className="method__content" data-aos="fade" data-aos-delay="100">
                    <h2 className="subtitle subtitle--light">Werkwijze</h2>
                    <h1 className="title">Ontwerpen en realiseren</h1>
                    <p className="desc">Uw website of applicatie uitbesteden en laten maken door Draze is een verstandige keuze indien u een website of applicatie wilt waarin u al uw wensen kunt laten realiseren.</p>
                    <p className="desc">Een website die volledig zelf geschreven wordt is ideaal indien u een volledig op maat gemaakte website wilt. Er zijn oneindig veel mogelijkheden voor een gewenst resultaat. Aan de hand van uw wensen wordt er een eerste schets gemaakt. Dit is een digitale schets waardoor u direct een goed beeld krijgt van het mogelijke ontwerp. In deze ontwerpfase kunt u zo veel laten aanpassen als u wilt om de website volledig naar wens te maken.</p>
                    <ul className="method__list">
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Digitale schets</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Live demo server</li>
                        <li className="method__item"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg> Aanpassingen naar wens</li>
                    </ul>
                    <p className="desc">Nadat u 100% tevreden bent met het ontwerp gaat de ontwikkel fase beginnen. Tijdens deze ontwikkel fase wordt de schets omgebouwd tot een volledige website. Via de live demo website kunt u mee kijken tijdens dit proces. Ook na de oplevering staat Draze voor u klaar door nieuwe functionaliteiten te ontwikkelen of door het eindresultaat up to date te houden. Lees meer over de ontwikkeling van <Link to="/websites" className="link">websites</Link> door Draze.</p>
                </article>
                <figure className="method__figure" data-aos="fade-left" data-aos-delay="100">
                    <img className="method__img" src={kentekenchecker} alt="Afbeelding van de Kenteken Checker app gemaakt door Draze" />
                </figure>
            </section>
            
        </section>
    )
}

export default Method
