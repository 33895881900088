const Privacy = () => {
    return (
        <section className="terms">
            <div className="container-small">
                <header className="terms__header" data-aos="fade" data-aos-delay="100">
                    <h2 className="subtitle subtitle--center subtitle--light">Draze.nl</h2>
                    <h1 className="title title--center">Privacyverklaring</h1>
                </header>
                <article data-aos="fade" data-aos-delay="200">
                    <h2 className="articletitle articletitle--margin">Waarborgen privacy</h2>
                    <p className="desc">Het waarborgen van de privacy van bezoekers van Draze is een belangrijke taak voor ons. Daarom beschrijven we in onze privacy policy welke informatie we verzamelen en hoe we deze informatie gebruiken.</p>
                    <h2 className="articletitle articletitle--margin">Toestemming</h2>
                    <p className="desc">Door de informatie en de diensten op Draze te gebruiken, gaat u akkoord met onze privacy policy en de voorwaarden die wij hierin hebben opgenomen.</p>
                    <h2 className="articletitle articletitle--margin">Persoonsgegevens die wij verwerken</h2>
                    <p className="desc">Draze verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt.</p>
                    <ul className="ul">
                        <li>- Voor- en achternaam</li>
                        <li>- E-mailadres</li>
                        <li>- Overige persoonsgegevens die u actief verstrekt bijvoorbeeld door het contactformulier op deze website in te vullen, in correspondentie en telefonisch</li>
                        <li>- Gegevens over uw activiteiten op onze website</li>
                        <li>- Internetbrowser en apparaat type</li>
                    </ul>
                    <h2 className="articletitle articletitle--margin">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
                    <p className="desc">Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via info@draze.nl, dan verwijderen wij deze informatie.</p>
                    <h2 className="articletitle articletitle--margin">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                    <p className="desc">Draze verwerkt uw persoonsgegevens voor de volgende doelen:</p>
                    <ul className="ul">
                        <li>- Het afhandelen van uw betaling</li>
                        <li>- Verzenden van onze nieuwsbrief en/of reclamefolder</li>
                        <li>- U te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</li>
                        <li>- U te informeren over wijzigingen van onze diensten en producten</li>
                        <li>- Om goederen en diensten bij u af te leveren</li>
                        <li>- Draze analyseert uw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op uw voorkeuren.</li>
                        <li>- Draze verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht zijn, zoals gegevens die wij nodig hebben voor onze belastingaangifte.</li>
                    </ul>
                    <h2 className="articletitle articletitle--margin">Hoe lang we persoonsgegevens bewaren</h2>
                    <p className="desc">Draze bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.</p>
                    <h2 className="articletitle articletitle--margin">Delen van persoonsgegevens met derden</h2>
                    <p className="desc">Draze verkoopt uw gegevens niet aan derden en verstrekt deze uitsluitend indien dit nodig is voor de uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke verplichting. Met bedrijven die uw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Draze blijft verantwoordelijk voor deze verwerkingen.</p>
                    <h2 className="articletitle articletitle--margin">Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                    <p className="desc">Draze gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van uw computer, tablet of smartphone. Draze gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die uw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen. Zie voor een toelichting: <a href="https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/" className="link" rel="noreferrer" target="_blank">Veilig Internetten</a>.</p>
                    <p className="desc">Op deze website worden ook cookies geplaatst door derden. Dit zijn analytische cookies die websitebezoek meet:</p>
                    <ul className="ul">
                        <li>Cookie: Google Analytics</li>
                        <li>Naam: _utma</li>
                        <li>Functie: Analytische cookie die websitebezoek meet</li>
                        <li>Bewaartermijn: 2 jaar</li>
                    </ul>
                    <p className="desc">Google Analytics is privacyvriendelijk ingesteld. Het laatste octet van het IP-adres is gemaskeerd en 'gegevens delen' is uitgezet. Daarnaast maakt Draze geen gebruik van andere Google-diensten in combinatie met de Google Analytics-cookies.</p>
                    <h2 className="articletitle articletitle--margin">Gegevens inzien, aanpassen of verwijderen</h2>
                    <p className="desc">U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Draze en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar <a href="mailto:info@draze.nl" className="link">info@draze.nl</a>. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. Draze wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <a href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" className="link" rel="noreferrer" target="_blank">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</a>.</p>
                    <h2 className="articletitle articletitle--margin">Hoe wij persoonsgegevens beveiligen</h2>
                    <p className="desc">TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde internetverbinding. Dit kunt u zien aan de adresbalk 'https' en het hangslotje in de adresbalk.</p>
                    <p className="desc">Draze neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <a href="mailto:info@draze.nl" className="link">info@draze.nl</a>.</p>
                </article>
            </div>
        </section>
    )
}

export default Privacy
