import { Link } from 'react-router-dom'
import logo from '../img/logo.png';
import instagram from '../img/instagram.png';
import facebook from '../img/facebook.png';

const Footer = () => {
    return (
        <footer className="page-footer">
            <div className="container">
                <section className="footer">

                    <article className="footer__item">
                        <figure className="logo">
                            <img className="logo__img" src={logo} alt="Logo van Draze.nl" />
                        </figure>
                        <div className="socials">
                            <a href="https://www.instagram.com/draze.nl/" rel="noreferrer" target="_blank">
                                <figure className="socials__figure">
                                    <img src={instagram} alt="Instagram logo" className="socials__img"/>
                                </figure>
                            </a>
                            <a href="https://www.facebook.com/drazewebdesign" rel="noreferrer" target="_blank">
                                <figure className="socials__figure">
                                    <img src={facebook} alt="Facebook logo" className="socials__img"/>
                                </figure>
                            </a>
                        </div>
                    </article>

                    <article className="footer__item">
                        <h2 className="smalltitle smalltitle--white">Navigatie</h2>
                        <ul className="footer__list">
                            <li className="footer__list-item"><Link to="/"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Home</Link></li>
                            <li className="footer__list-item"><Link to="/websites"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Websites</Link></li>
                            <li className="footer__list-item"><Link to="/applicaties"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Applicaties</Link></li>
                            <li className="footer__list-item"><Link to="/projecten"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Projecten</Link></li>
                            <li className="footer__list-item"><Link to="/contact"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Contact</Link></li>
                        </ul>
                    </article>

                    <article className="footer__item">
                        <h2 className="smalltitle smalltitle--white">Beleid</h2>
                        <ul className="footer__list">
                            <li className="footer__list-item"><Link to="/algemene-voorwaarden"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Algemene voorwaarden</Link></li>
                            <li className="footer__list-item"><Link to="/privacyverklaring"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" /></svg> Privacyverklaring</Link></li>
                        </ul>
                    </article>

                    <article className="footer__item">
                        <h2 className="smalltitle smalltitle--white">Contact</h2>
                        <ul className="footer__list">
                            <li className="footer__list-item"><a href="mailto:info@draze.nl"><svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" /></svg> info@draze.nl</a></li>
                        </ul>
                    </article>

                </section>

                <span>
                    Copyright &copy; 2020 &mdash; 2021 <Link to="/">Draze.nl</Link>
                </span>
            </div>
        </footer>
    )
}

export default Footer
