import icon_ontwerpen from '../img/icon_ontwerpen.png';
import icon_ontwikkelen from '../img/icon_ontwikkelen.png';
import icon_opleveren from '../img/icon_opleveren.png';

const MethodGrid = () => {
    return (
        <section className="container">

            <section className="grid grid--top">
                <article className="grid__item" data-aos="fade-up" data-aos-delay="100">
                    <figure className="grid__figure">
                        <img className="grid__img" src={icon_ontwerpen} alt="Icon over het ontwerp proces" />
                    </figure>
                    <h2 className="smalltitle smalltitle--center">Ontwerpen</h2>
                    <p className="desc">Alle maatwerk applicaties worden naar wens ontworpen middels een digitale schets.</p>
                </article>

                <article className="grid__item" data-aos="fade-up" data-aos-delay="200">
                    <figure className="grid__figure">
                        <img className="grid__img" src={icon_ontwikkelen} alt="Icon over het ontwikkel proces" />
                    </figure>
                    <h2 className="smalltitle smalltitle--center">Ontwikkelen</h2>
                    <p className="desc">De applicatie wordt ontwikkeld en u kunt het proces live meevolgen via de demo omgeving.</p>
                </article>

                <article className="grid__item" data-aos="fade-up" data-aos-delay="300">
                    <figure className="grid__figure">
                        <img className="grid__img" src={icon_opleveren} alt="Icon over het oplever proces" />
                    </figure>
                    <h2 className="smalltitle smalltitle--center">Opleveren</h2>
                    <p className="desc">Wanneer de applicatie volledig naar wens gerealiseerd is, wordt deze opgeleverd.</p>
                </article>
            </section>

        </section>
    )
}

export default MethodGrid
